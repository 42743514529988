import React, { Component } from 'react';
import cs from 'classnames';
import Swiper from 'swiper';
import styles from './SliderArticleHeader.module.sass';
// import Img from 'gatsby-image';

class SliderArticleHeader extends Component {

  componentDidMount() {
    new Swiper('.swiper-container', {
      direction: 'horizontal',
      slidesPerView: 'auto',
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  render() {
    const { data: { datoCmsArticle: { slider } } } = this.props;
    return (
      <div id="headerSlider" className={cs(styles.headerSlider, 'swiper-container')}>
        <div className={styles.navigation}>
          <div className={cs('swiper-button-prev', styles.prevBtn)}></div>
          <div className={cs('swiper-button-next', styles.nextBtn)}></div>
        </div>
        <div className={cs('swiper-wrapper', styles.sliderWrap)}>
          {slider && slider.map(slide => {
            return (
              <div className={cs('swiper-slide', styles.slide)} key={slide.alt}>
                  <img className={styles.dog} src={slide.url} alt={slide.alt}/>
              </div>
            );
          })}
        </div>
        <div className={cs('swiper-pagination', styles.pagination)}></div>
      </div>
    );
  }
};


export default SliderArticleHeader;